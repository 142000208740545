$(document).ready(function(){
  var table_data = {};
  var survey_count = 0;

  const url = "https://backend.ratingfish.com/api/v1.0/transparency";
  fetch(url)
    .then(r => r.json())
    .then(data => {
        table_data['value'] = data.data.chart_data.map(item => {
          return Number(item.mmr)
        });
        table_data['dates'] = data.data.chart_data.map(item => {
          return `${item.yyyy}-${item.mm}`
        });
        ["subscribers", "monthly_reccuring_revenue", "annual_run_rate",
          "team_size", "monthly_growth", "chrun_rate", "total_csr_helped",
          "survey_count"
        ].forEach(id => {
           if(id === 'survey_count'){ survey_count = parseInt(data.data[id]); }
           let elem = $(`#${id}`);
           if(elem){
             var e = parseInt(data.data[id]).toLocaleString("en-US");
             if(["monthly_reccuring_revenue", "annual_run_rate"].includes(id)){
               e = `$${e}`;
             }
             if(["monthly_growth", "chrun_rate"].includes(id)){
               e = `${e}%`;
             }
             elem.text(e);
           }
        });


    "use strict";

    // smooth scrolling
    $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .not('[data-toggle="pill"]')
    .not('[data-toggle="tab"]')
    .not('[data-toggle="collapse"]')
    .click(function(event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top
          }, 800);
        }
      }
    });

    $('.typed-words').each(function(index, el) {
      var typed = new Typed('.typed-words', {
        strings: $(el).data('strings'),
        loop: true,
        startDelay: 0,
        backDelay: 1500,
        typeSpeed: 30,
        backSpeed: 0,
        showCursor: false
      });
    });

    // Transparency chart
    if($('#chart').length > 0) {
      var options = {
        series: [{
        name: "MRR Growth",
        data: table_data.value,
        color: '#2f80ed'
      }],
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      grid: {
        padding: {
          bottom: -10,
        },
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 4,
      },
      stroke: {
        curve: 'smooth'
      },
      labels: table_data.dates,
      xaxis: {
        type: 'category',
        tickPlacement: 'on',
        labels: {
          rotate: -30,
        },
      },
      yaxis: {
        labels: {
          formatter: function(value) {
            // thousand format
            return '$'+value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
        }
      },
      };

      var chart = new ApexCharts(document.querySelector("#chart"), options);
      chart.render();
    }

    // Surveys count
    if($('#surveyCountNum').length > 0) {
      var numAnim = new countUp.CountUp('surveyCountNum', survey_count);
      numAnim.start();
    }
  })
});
